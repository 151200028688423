import {
  Button,
  Card,
  Flex,
  FlexItem,
  FormField,
  Logo,
  Text,
  TextInput
} from '@volue/wave-react';
import { useState } from 'react';

import { useActions, useAppState } from '@/overmind';
import { OnBoardingRequestStatus } from '@/overmind/types';

const Onboard = () => {
  const { registerCompanyName, signOut } = useActions();
  const { onBoardingStatus } = useAppState();
  const [company, setCompany] = useState<string>('');

  const promptCompany = () => (
    <Flex
      style={{ backgroundColor: '#082b33', width: '100vw', height: '100vh' }}
      flow="column"
      cross="center"
      main="center"
    >
      <Flex
        flow="column"
        cross="center"
        main="center"
        style={{ height: '700px' }}
      >
        <div style={{ color: 'white' }}>
          <Logo />
        </div>
        <FlexItem flex="1" />
        <Card style={{ width: '380px', padding: '20px' }}>
          <Flex flow="column" cross="center" main="center">
            <Text
              as="div"
              fontSize="beta"
              lineHeight="base"
              fontFamily="display"
              style={{ marginBottom: '20px', marginTop: '10px' }}
            >
              {'Register company'}
            </Text>
            <Text
              as="div"
              fontSize="zeta"
              lineHeight="base"
              fontFamily="display"
              align="center"
              className="mBm"
            >
              {
                'To complete your registration, please provide your company name.'
              }
            </Text>
            <FormField
              style={{ width: '100%', marginBottom: '10px' }}
              as={Flex}
              flow="column"
              gap="spacingXs"
              validationStatus="error"
              isRequired
            >
              <TextInput
                aria-label="Company name"
                placeholder="Company name"
                onChange={e => setCompany(e.target.value)}
              />
              {/* {company === '' ? (
                <FormField.FeedbackMessage>
                  <FormField.FeedbackIcon />
                  {'Company name is required.'}
                </FormField.FeedbackMessage>
              ) : (
                <></>
              )} */}
            </FormField>
            <Button
              style={{
                width: '100%',
                textAlign: 'center',
                marginBottom: '10px'
              }}
              isDisabled={company === ''}
              onClick={() => {
                registerCompanyName(company);
              }}
            >
              {'Register'}
            </Button>
            <Text
              as="div"
              fontSize="zeta"
              lineHeight="base"
              fontFamily="display"
              align="center"
              className="mBm"
            >
              {'By creating an account or signing you agree to our '}
              <Text fontWeight="semibold">{'Terms and Conditions'}</Text>
            </Text>
            <Text
              as="div"
              fontSize="zeta"
              lineHeight="base"
              fontFamily="display"
              align="center"
              className="mBm"
            >
              {'or'}
            </Text>
            <Button
              style={{
                width: '100%',
                textAlign: 'center',
                marginBottom: '10px'
              }}
              variant="ghost"
              onClick={() => signOut()}
            >
              {'Sign in with another account'}
            </Button>
          </Flex>
        </Card>
        <FlexItem flex="1" />
        <FlexItem flex="1" />
      </Flex>
    </Flex>
  );

  const thankYou = () => (
    <Flex
      style={{ backgroundColor: '#082b33', width: '100vw', height: '100vh' }}
      flow="column"
      cross="center"
      main="center"
    >
      <Flex
        flow="column"
        cross="center"
        main="center"
        style={{ height: '700px' }}
      >
        <div style={{ color: 'white' }}>
          <Logo />
        </div>
        <FlexItem flex="1" />
        <Card style={{ width: '380px', padding: '20px' }}>
          <Flex flow="column" cross="center" main="center">
            <Text
              as="div"
              fontSize="beta"
              lineHeight="base"
              fontFamily="display"
              style={{ marginBottom: '20px', marginTop: '10px' }}
            >
              {'Thank you'}
            </Text>
            <Text
              as="div"
              fontSize="zeta"
              lineHeight="base"
              fontFamily="display"
              align="center"
              className="mBm"
            >
              {
                'Your account registration is now being processed by our sales team. Please allow up to 3 days for registering.'
              }
            </Text>
            <Text
              as="div"
              fontSize="zeta"
              lineHeight="base"
              fontFamily="display"
              align="center"
              className="mBm"
            >
              {
                'We will notify you by e-mail as soon as we have processed your registration.'
              }
            </Text>
            <Text
              as="div"
              fontSize="zeta"
              lineHeight="base"
              fontFamily="display"
              align="center"
              className="mBm"
            >
              {'You may close this browser tab.'}
            </Text>
          </Flex>
        </Card>
        <FlexItem flex="1" />
        <FlexItem flex="1" />
      </Flex>
    </Flex>
  );

  // return onBoarding.some(r => r.status === OnBoardingRequestStatus.pending) ||
  return onBoardingStatus.statusOnBackend === OnBoardingRequestStatus.pending ||
    onBoardingStatus.hasBeenSentOnFrontend
    ? thankYou()
    : promptCompany();
};

export default Onboard;
