import { Button, Tooltip } from '@volue/wave-react';
import { useState } from 'react';

import { useActions } from '@/overmind';
import type { ProductContext } from '@/overmind/types';

export const RequestTrialButton = (props: {
  trialStatus: string;
  productContext: ProductContext;
  acceptTerms: boolean;
}) => {
  const { pageOpened } = useActions();
  const { requestTrial, setProductContext } = useActions().marketplace;
  const [isActing, setIsActing] = useState(false);

  const handleClick = async () => {
    setIsActing(true);
    setProductContext({ ...props.productContext, lastAction: 'requestTrial' });
    await requestTrial(props.productContext);
    setIsActing(false);
    pageOpened({ page: 'thankyou' });
  };
  switch (props.trialStatus) {
    case 'Available':
      return (
        <Button
          isLoading={isActing}
          loadingText="Submitting..."
          variant="strong"
          size={'small'}
          isNarrow
          onClick={handleClick}
          disabled={!props.acceptTerms}
        >
          {'Request trial'}
        </Button>
      );
    case 'Pending':
      return (
        <Button variant="strong" size={'small'} isNarrow disabled>
          {'Trial request pending...'}
        </Button>
      );
    case 'Expired':
      return (
        <Tooltip
          content={
            'Trial period for this product expired. Please contact sales.'
          }
        >
          <Button variant="strong" size={'small'} isNarrow disabled>
            {'Trial expired...'}
          </Button>
        </Tooltip>
      );
    default:
      return <></>;
  }
};
