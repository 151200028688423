import { catchError, pipe } from 'overmind';

import type { Context } from '@/overmind';
import type { RouteCtx } from '@/overmind/effects/router';
import type { ProductContext } from '@/overmind/types';

export const loadProducts = pipe(
  async ({ state, effects }: Context, productLine: string | undefined) => {
    state.isLoading = true;
    const products = await effects.api.getProducts(productLine);
    if (state.marketplace.categoryList?.length === 0)
      state.marketplace.categoryList = products;
    state.isLoading = false;
  },
  catchError(({ state, actions }: Context, error: any) => {
    state.isLoading = false;
    actions.handleError(error);
  })
);

export const loadProductsAndMyProducts = pipe(
  async ({ state, effects }: Context, productLine: string | undefined) => {
    state.isLoading = true;
    const tenant = state.signedIdUser?.activeTenant?.id ?? '';
    state.marketplace.categoryList = await effects.api.getProductsAndMyProducts(
      productLine,
      tenant
    );
    state.isLoading = false;
  },
  catchError(({ state, actions }: Context, error: any) => {
    state.isLoading = false;
    actions.handleError(error);
  })
);

export const loadProductsById = pipe(
  async ({ state, effects }: Context, id: string) => {
    state.isLoading = true;
    state.marketplace.categoryList = await effects.api.getProductsById(id);
    state.isLoading = false;
  },
  catchError(({ state, actions }: Context, error: any) => {
    state.isLoading = false;
    actions.handleError(error);
  })
);

export const loadProductRegions = pipe(
  async ({ state, effects }: Context) => {
    state.marketplace.productRegions = await effects.api.getProductRegions();
  },
  catchError(({ state, actions }: Context, error: any) => {
    state.isLoading = false;
    actions.handleError(error);
  })
);

export const setProductContext = pipe(
  async ({ state }: Context, productContext: ProductContext) => {
    state.marketplace.productContext = productContext;
  },
  catchError(({ actions }: Context, error: any) => {
    actions.handleError(error);
  })
);

export const getQuote = pipe(
  async ({ state, effects }: Context, productContext: ProductContext) => {
    if (productContext.productOfInterest) {
      state.isLoading = true;
      state.marketplace.quotes[productContext.productOfInterest] =
        await effects.api.getQuote(productContext);
      state.isLoading = false;
    }
  },
  catchError(({ state, actions }: Context, error: any) => {
    state.isLoading = false;
    actions.handleError(error);
  })
);

export const requestTrial = pipe(
  async ({ state, effects }: Context, productContext: ProductContext) => {
    if (productContext.productOfInterest) {
      state.isLoading = true;
      const tenant = state.signedIdUser?.activeTenant?.id ?? '';
      state.marketplace.trials[productContext.productOfInterest] =
        await effects.api.requestTrial(productContext, tenant);
      state.isLoading = false;
    }
  },
  catchError(({ state, actions }: Context, error: any) => {
    state.isLoading = false;
    actions.handleError(error);
  })
);

export const contactMe = pipe(
  async ({ state, effects }: Context, productContext: ProductContext) => {
    if (productContext.productOfInterest) {
      state.isLoading = true;
      await effects.api.contactMe(productContext);
      state.isLoading = false;
    }
  },
  catchError(({ state, actions }: Context, error: any) => {
    state.isLoading = false;
    actions.handleError(error);
  })
);

/* export const marketplaceRouted = (
  { actions }: Context,
  { params }: RouteCtx
) => {
  actions.internal.setRoute({ route: 'marketplace', params });
  actions.marketplace.loadProducts('');
}; */

export const productsRouted = ({ actions }: Context, { params }: RouteCtx) => {
  if (!params?.productLine) {
    params.productLine = 'Insight';
  }
  actions.internal.setRoute({ route: 'products', params });
  actions.marketplace.loadProductsAndMyProducts(params.productLine);
  actions.marketplace.loadProducts(params.productLine);
};

export const setLastDialogProduct = ({ state }: Context, productId: string) => {
  state.marketplace.lastDialogProduct = productId;
};
