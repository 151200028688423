export type User = {
  sid: string;
  userName: string;
  displayName: string;
  tenants?: AuthorizedUserTenantInfo[];
  activeTenant?: AuthorizedUserTenantInfo;
};

export type UserClaim = {
  type: string;
  value: string;
};

export type ProductCategory = {
  name: string;
  products: ProductSummary[];
};

export type ProductSummary = {
  id: string;
  name: string;
  code: string;
  productLine: string;
  workProcess: string;
  modules: string[];
  description: string;
  newsLabel: string;
  notificationLabel: string;
  heroImageUrl: string;
  learnMore: LabeledUrl;
  launchButton: LabeledUrl;
  dealType: 'None' | 'Asset' | 'Subscription';
  trialStatus: 'Available' | 'Pending' | 'Active' | 'Expired';
};

export type ProductRegion = {
  symbol: string;
  name: string;
};

export type AuthorizedUserInfo = {
  tenants?: AuthorizedUserTenantInfo[];
  identityAdminUrl: string;
};

export type AuthorizedUserTenantInfo = {
  name: string;
  id: string;
  organizationName: string;
  organizationId: string;
};

export type InsightProductDetails = ProductSummary & {
  longDescription: string;
  availability: string;
  sideText: string;
};

export type LabeledUrl = {
  label: string;
  url: string;
};

export type ProductContext = {
  id: string | undefined;
  productOfInterest: string | undefined;
  productLine: string | undefined;
  lastAction: 'getQuote' | 'requestTrial' | undefined;
};

export type ProductQuoteResponse = {
  leadActionResult: 'None' | 'New' | 'AlreadyExists' | 'Error';
  name: string;
};

export type ProductTrialResponse = {
  leadActionResult: 'None' | 'New' | 'AlreadyExists' | 'Error';
  name: string;
};

export type ProductContactMeResponse = {
  name: string;
};

export type OnBoardingRequest = {
  companyName: string;
  status: OnBoardingRequestStatus;
};

export enum OnBoardingRequestStatus {
  created = 'Created',
  pending = 'Pending',
  approved = 'Approved',
  rejected = 'Rejected',
  unknown = 'Unknown'
}
