import { Button, Flex, SvgIcon, Text } from '@volue/wave-react';

import { useActions, useAppState } from '@/overmind';

const ThankYou = () => {
  const { productContext, quotes, trials } = useAppState().marketplace;
  const { pageOpened } = useActions();
  const { setLastDialogProduct } = useActions().marketplace;

  let message =
    'Thank you for your interest in our products. You will receive a message from our sales team soon.';

  const alternativeMessage =
    'Thanks again for your interest in our products. Please be patient to receive a message from our sales team.';

  if (!productContext.productOfInterest) {
    return <></>;
  }

  if (productContext.lastAction === 'getQuote') {
    const quote = quotes[productContext.productOfInterest];
    if (quote.leadActionResult === 'AlreadyExists') {
      message = alternativeMessage;
    }
  }

  if (productContext.lastAction === 'requestTrial') {
    const trial = trials[productContext.productOfInterest];
    if (trial.leadActionResult === 'AlreadyExists') {
      message = alternativeMessage;
    }
  }

  return (
    <Flex flow="column" cross="center">
      <SvgIcon iconName="validation" size="xLarge" strokeWidth={1} />
      <Text as="div" fontSize="alpha" lineHeight="gamma" fontFamily="display">
        {'Thank you'}
      </Text>
      <Text
        as="div"
        fontSize="gamma"
        lineHeight="base"
        fontFamily="display"
        align="center"
        className="w50 mBm"
      >
        {message}
      </Text>
      <Button
        onClick={() => {
          setLastDialogProduct('');
          pageOpened({
            page: 'products',
            query: { productLine: 'Insight', productId: productContext.id }
          });
        }}
      >
        {'Back to Product'}
      </Button>
    </Flex>
  );
};

export default ThankYou;
