import Navigo from 'navigo';
import queryString from 'query-string';

export type Params = Record<string, string | undefined>;
export type RouteCtx = { params: Params; path: string };
export type RouteCallback = ({ params, path }: RouteCtx) => void;
export type RouteDefinition =
  | RouteCallback
  | {
      start: RouteCallback;
      exit: RouteCallback;
    };

const isCallback = (route: RouteDefinition): route is RouteCallback => {
  return typeof route === 'function';
};

const router = new Navigo('/');

export default {
  initialize(routes: Record<string, RouteDefinition>) {
    Object.keys(routes).forEach(url => {
      const route = routes[url];
      if (isCallback(route)) {
        router.on(url, match => {
          route({
            params: {
              ...match?.params
            },
            path: url
          });
        });
      } else {
        router.on(
          url,
          match => {
            route.start({
              params: {
                ...match?.params
              },
              path: url
            });
          },
          {
            leave: (done, leaveMatch) => {
              const params = Array.isArray(leaveMatch)
                ? leaveMatch[0].params
                : leaveMatch.params;
              route.exit({
                params: {
                  ...params
                },
                path: url
              });
              done();
            }
          }
        );
      }
    });
    router.resolve();
  },
  goTo({
    url,
    params = {},
    query,
    hash
  }: {
    url: string;
    params?: Params;
    query?: Params;
    hash?: Params;
  }) {
    const urlWithReplacedParams = Object.keys(params).reduce(
      (currentUrl, param) => {
        return currentUrl.replace(`:${param}`, params[param]!);
      },
      url
    );
    const stringQuery = query ? '?' + queryString.stringify(query) : '';
    const hashQuery = hash ? '#' + hash : '';

    router.navigate(`${urlWithReplacedParams}${stringQuery}${hashQuery}`);
  }
};
