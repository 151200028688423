export default {
  async wait(ms: number) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  },

  async getSignedInUser(fn: () => Promise<void>) {
    await fn();
  }
};
